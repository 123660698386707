<template>
    <div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title">
                    <h3 class="card-label">
                        {{ $t('payable_cheques.payable_cheques')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
                    <router-link to="/cheques-cycle/payable-cheques" class="btn btn-light font-weight-bolder">
                        <v-icon>mdi-chevron-left</v-icon>
                        {{ $t('back') }}
                    </router-link>
                    <!--end::Button-->
                </div>
            </div>

            <div class="card-body">
                    <b-tabs content-class="mt-3">
                        <b-tab :title="$t('activity_log.basic_information')" active>
                            <br>
                            <div>
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th>{{$t('payable_cheques.book_number')}}</th>
                                            <td>{{data.book_number}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('payable_cheques.cheques_no')}}</th>
                                            <td>{{data.cheques_no}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('payable_cheques.status')}}</th>
                                            <td>{{data.status_name}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('payable_cheques.amount')}}</th>
                                            <td>{{data.amount}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('payable_cheques.received_account')}}</th>
                                            <td>{{data.received_account}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('payable_cheques.cheques_name')}}</th>
                                            <td>{{data.cheques_name}}</td>
                                        </tr>
                                         <tr>
                                            <th>{{$t('payable_cheques.issue_date')}}</th>
                                            <td>{{data.issue_date}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('payable_cheques.due_date')}}</th>
                                            <td>{{data.due_date}}</td>
                                        </tr>

                                        <tr>
                                            <th>{{$t('payable_cheques.bank_name')}}</th>
                                            <td>{{data.bank_name}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('payable_cheques.branch')}}</th>
                                            <td>{{data.branch_name}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('payable_cheques.currency')}}</th>
                                            <td>{{data.currency_name}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('payable_cheques.bank_account')}}</th>
                                            <td>{{account_name}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('payable_cheques.notes')}}</th>
                                            <td>{{data.notes}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('payable_cheques.collect_date')}}</th>
                                            <td>{{data.collect_date}}</td>
                                        </tr>

                                        <tr>
                                            <th>{{$t('created_at')}}</th>
                                            <td>{{data.created_date}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-tab>
                        <b-tab :title="$t('activity_log.activity_log')">
                            <br>
                            <activity-log :inner-key="key" :id="id"></activity-log>
                        </b-tab>
                    </b-tabs>
                </div>
        </div>
        <!--end::customer-->
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ActivityLog from "@/view/content/widgets/details/ActivityLog";

    export default {
        name: "view-payable_cheques",
        components:{'activity-log': ActivityLog},
        data() {
            return {
                mainRoute: 'chequescycle/payable-cheques',
                mainRouteDependency: 'base/dependency',
                id: this.$route.params.id? this.$route.params.id : null,
                key: 'PayableCheque',
                data: {},
                account_name: null,
            };
        },

        methods: {
            async getData() {
                ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
                    this.data = response.data.data;
                });
            },
             getAccountingRoute() {
                 ApiService.get(this.mainRouteDependency + "/accounts_routing",{params:{key: 'payable_cheque_account'}}).then((response) => {
                    this.account_name = response.data.data.account_name;
                });
            },
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.payable_cheques"),route:'/cheques-cycle/payable-cheques'}, {title: this.$t('view')}]);

            if (this.id) {
                this.getData();
            }
            this.getAccountingRoute();
        },
    };
</script>